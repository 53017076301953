import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [DatePipe]
})
export class FooterComponent implements OnInit, OnDestroy {
 
  myDate: any;
  @Input() customTemplate: TemplateRef<any>;

  constructor(private datePipe: DatePipe) {
    this.myDate = new Date()
    this.myDate = this.datePipe.transform(new Date(), 'yyyy');
  }
  ngOnInit() {
  }

  ngOnDestroy(): void {}
}
